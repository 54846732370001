import type { Settings } from '@/types/app'
import { authToken } from '@/composables/api'

export const useAppStore = defineStore('app', () => {
  const api = useApi()

  const loading = ref(false)

  const appReady = ref(false)

  const settings = ref<Settings>({
    city: '',
    asapOnly: false,
    orderFields: {},
    currencySymbol: '',
    mutualZoneSettings: true
  })

  async function fetchAppSettings() {
    settings.value = await api.getAppSettings()
  }

  async function fetchInitialData() {
    const catalogStore = useCatalogStore()
    const cartStore = useCartStore()
    const orderStore = useOrderStore()
    const userStore = useUserStore()

    if (sessionStorage.getItem('oldMerchantKey')) {
      orderStore.selectedAddressId = null
    }

    const promises = [
      fetchAppSettings(),
      cartStore.fetchCart(),
      orderStore.fetchPickupPoints(),
      orderStore.fetchOrderTypes(),
      fetchHappyHoursList()
    ]

    if (authToken.value) {
      promises.push(orderStore.fetchAddressList(), userStore.fetchUser())
    }

    await Promise.all(promises)

    if (orderStore.selectedAddress) {
      await orderStore.fetchDeliveryArea()
    }

    await catalogStore.fetchShowcase()

    appReady.value = true
  }

  const yandexMapApiKey = computed(
    () =>
      (import.meta.env.DEV && import.meta.env.VITE_YANDEX_MAP_API_KEY) ||
      settings.value.yandexMapApiKey
  )

  const hasMerchantStatuses = computed<boolean>(() => {
    const statuses = settings.value.merchantStatuses
    return Boolean(statuses && Object.values(statuses).some((item) => item.active))
  })

  return {
    appReady,
    settings,
    yandexMapApiKey,
    loading,
    hasMerchantStatuses,
    fetchAppSettings,
    fetchInitialData
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
