import type { App } from 'vue'
import { useToastPlugin } from './toast'
import { useCordova } from './cordova'
import { parseSearchParams } from './search'

export const registerPlugins = (app: App) => {
  parseSearchParams()
  useCordova()
  useToastPlugin(app)
}
