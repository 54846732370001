import type { Category } from '@/types/catalog'

const categories = ref(new Map<Category, IntersectionObserverEntry>())

const activeCategory = computed<Category | undefined>(() => {
  return Array.from(categories.value.entries())
    .filter(([, entry]) => entry.isIntersecting)
    .sort(([, a], [, b]) => {
      if (a.intersectionRatio === b.intersectionRatio) {
        if (a.time === b.time) {
          return 0
        }
        return a.time > b.time ? -1 : 1
      }
      return a.intersectionRatio > b.intersectionRatio ? -1 : 1
    })[0]?.[0]
})

function setCategory(category: Category, entry: IntersectionObserverEntry) {
  categories.value.set(category, entry)
}

function clear() {
  categories.value.clear()
}

const title = useTitle()

watch(activeCategory, () => {
  title.value = activeCategory.value?.name || null
})

export const useCatalogObserver = () => {
  return {
    clear,
    setCategory,
    activeCategory,
    categories
  }
}
