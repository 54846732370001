<script setup lang="ts">
import range from 'lodash/range'
import type { Category } from '@/types/catalog'

const props = defineProps<{
  category: Category
}>()

const { setCategory } = useCatalogObserver()

const section = ref(null)

useIntersectionObserver(
  section,
  ([entry]) => {
    setCategory(props.category, entry)
  },
  { threshold: range(0, 1, 0.01) }
)
</script>

<template>
  <section ref="section" class="scroll-mt-16">
    <h2 class="mb-2.5 text-xl font-bold text-dark dark:text-neutral-200">
      {{ category.name }}
    </h2>
    <ProductGrid>
      <slot name="default"></slot>
    </ProductGrid>
  </section>
</template>
