import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'ProductDetails') {
      return { top: 0 }
    } else if (savedPosition) {
      return savedPosition
    }
  }
})
