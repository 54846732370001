export const useCurrency = () => {
  function priceFormat(input: string | number) {
    const appStore = useAppStore()

    let currencySymbol = appStore.settings.currencySymbol

    if (currencySymbol === ' Р' || !currencySymbol) {
      currencySymbol = ' ₽'
    }

    const value = +Number(input).toFixed(2) || 0

    const number = Number.isInteger(value) ? value : value.toFixed(2)

    return number + currencySymbol
  }

  return {
    priceFormat
  }
}
