<script setup lang="ts">
import type { Product } from '@/types/catalog'
import { authToken } from '@/composables/api'
import { redirectTo } from '@/utils'

const props = defineProps<{
  product: Product
}>()

const router = useRouter()
const { priceFormat } = useCurrency()
const cartStore = useCartStore()
const orderStore = useOrderStore()

const toast = useToast()

const product = computed(() => props.product)

const qty = computed(() => cartStore.qtyById?.[product.value.id])

const cartItem = computed(() => cartStore.cartItemsById?.[product.value.id]?.at(-1))

const isComplexProduct = computed(() =>
  Boolean(product.value.hasAddons || product.value.sizes?.length)
)

const isDisabled = ref(false)

function showOrderTypeModal() {
  orderStore.modalOrderTypeVisible = true
}

async function showDetailsPage() {
  if (!authToken.value) {
    return
  }

  if (!cartStore.canUseCart) {
    return showOrderTypeModal()
  }

  await router.push({
    name: 'ProductDetails',
    params: {
      productId: product.value.id
    }
  })
}

async function addToCart() {
  try {
    if (!authToken.value) {
      redirectTo('/login')
      return
    }

    if (!cartStore.canUseCart) {
      return showOrderTypeModal()
    }

    if (isComplexProduct.value) {
      await showDetailsPage()
    } else {
      await cartStore.addToCart(product.value, 1)
      useEcommerce().bus.emit({
        name: 'addCartItem',
        data: {
          product: product.value,
          qty: 1
        }
      })
    }
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message)
    } else {
      alert(error)
    }
  }
}

async function changeQty(qty: number) {
  if (!cartStore.canUseCart) {
    return showOrderTypeModal()
  }

  if (!cartItem.value) return

  try {
    isDisabled.value = true

    if (qty > 0 && isComplexProduct.value) {
      await showDetailsPage()
    } else {
      await cartStore.updateCartItemQty({
        index: cartItem.value.index,
        qty: qty
      })
    }
  } finally {
    isDisabled.value = false
  }
}
</script>

<template>
  <div class="flex flex-col overflow-hidden rounded-xl bg-white dark:bg-neutral-900">
    <div class="aspect-h-3 aspect-w-4" @click="showDetailsPage">
      <Image :src="product.image" :alt="product.name" class="object-contain" loading="lazy" />
    </div>
    <div class="flex grow flex-col gap-2.5 p-2.5">
      <!-- <div
        v-if="happyHoursLabel"
        class="flex items-center gap-1.5 rounded bg-amber-400/20 px-1.5 py-1 text-2xs text-neutral-900"
      >
        <i-ri-time-line class="shrink-0 text-sm" />
        <div>
          <div class="font-medium">{{ happyHoursLabel.description }}</div>
          <div>Скидка {{ happyHoursLabel.discount }} {{ happyHoursLabel.time }}</div>
        </div>
      </div> -->

      <div v-if="product.price" class="flex items-center gap-1">
        <div
          class="text-base font-bold leading-none"
          :class="[product.oldPrice ? 'text-red-500' : 'text-dark dark:text-neutral-200']"
        >
          <span v-if="isComplexProduct">от</span>
          {{ priceFormat(product.price) }}
        </div>
        <div v-if="product.oldPrice" class="align-middle text-xs line-through">
          {{ priceFormat(product.oldPrice) }}
        </div>
      </div>

      <div class="space-y-1">
        <div v-if="product.stickers?.length" class="flex flex-wrap items-center gap-1">
          <ProductSticker
            v-for="sticker in product.stickers"
            :key="sticker.id"
            :sticker="sticker"
            class="text-xs"
          />
        </div>

        <div class="line-clamp-3 break-words text-0.5xs leading-tight text-dark dark:text-neutral-200">
          {{ product.name }}
        </div>
        <div v-if="product.weight" class="text-2xs text-gray dark:text-neutral-400">
          Вес: {{ product.weight }} {{ product.measure || 'г.' }}
        </div>
      </div>
      <div class="mt-auto">
        <div v-if="product.notAvailable" class="text-xs font-light leading-tight">
          Товар временно недоступен
        </div>
        <QtyInput
          v-else-if="qty"
          :value="qty"
          :disabled="isDisabled"
          @minus="changeQty(-1)"
          @plus="changeQty(+1)"
        />
        <button
          v-else
          class="flex w-full items-center justify-center gap-1 rounded-md bg-primary/20 px-5 py-1 font-medium text-primary"
          @click="addToCart"
        >
          <i-ri-add-line class="shrink-0" />
          <span>Добавить</span>
        </button>
      </div>
    </div>
  </div>
</template>
