<script setup lang="ts">
const appStore = useAppStore()
</script>

<template>
  <div class="flex min-h-screen flex-col gap-3 bg-light pt-3 dark:bg-black">
    <ImportantMessages v-if="appStore.hasMerchantStatuses" />

    <StickyCategories />

    <div class="container min-h-0 grow space-y-5 pb-10">
      <slot></slot>
    </div>
  </div>
</template>
