import type { Category, Showcase } from '@/types/catalog'

export const useCatalogStore = defineStore('catalog', () => {
  const categories = ref<Category[] | null>(null)
  const showcase = ref<Showcase | null>(null)

  const search = useSessionStorage('catalogSearch', '')

  async function fetchCategories() {
    categories.value = await useApi().getCategories()
  }

  async function fetchShowcase() {
    showcase.value = await useApi().getShowcase()
  }

  const categoriesById = computed(() => {
    return categories.value?.reduce(
      (result, category) => {
        result[category.id] = category
        return result
      },
      {} as {
        [categoryId: string]: Category
      }
    )
  })

  return {
    categories,
    showcase,
    categoriesById,
    fetchCategories,
    fetchShowcase,
    search
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCatalogStore, import.meta.hot))
}
