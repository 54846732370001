<script setup lang="ts">
import type { DeliveryArea } from '@/types/order'

defineProps<{
  deliveryArea: DeliveryArea
}>()

const { priceFormat } = useCurrency()
</script>

<template>
  <div class="space-y-2.5">
    <div class="flex items-center justify-between gap-4">
      <div class="w-2/3">Стоимость доставки</div>
      <div class="w-1/3 text-right">
        {{ priceFormat(deliveryArea.deliveryCost || 0) }}
      </div>
    </div>
    <div class="flex items-center justify-between gap-4">
      <div class="w-2/3">Время доставки</div>
      <div class="w-1/3 text-right">
        {{ deliveryArea.deliveryTime || '-' }}
      </div>
    </div>
    <div class="flex items-center justify-between gap-4">
      <div class="w-2/3">Минимальная сумма заказа</div>
      <div class="w-1/3 text-right">
        {{ deliveryArea.minOrder ? priceFormat(deliveryArea.minOrder) : '-' }}
      </div>
    </div>
    <div v-if="deliveryArea.freeDelivery" class="flex items-center justify-between gap-4">
      <div class="w-2/3">Сумма для бесплатной доставки</div>
      <div class="w-1/3 text-right">
        {{ deliveryArea.freeDelivery ? priceFormat(deliveryArea.freeDelivery) : '-' }}
      </div>
    </div>
    <div class="">
      Способы оплаты:
      <span
        v-for="payment in deliveryArea.availablePayments"
        :key="payment.code"
        class="m-1 inline-block rounded-md bg-green-400/10 px-2 py-1 text-xs font-light leading-none text-green-600"
      >
        {{ payment.label }}
      </span>
    </div>
  </div>
</template>
