import type { Ref } from 'vue'

const observables = ref(new Set<Ref<Boolean>>())

watch(
  observables,
  (observables) => {
    window.parent.postMessage(
      {
        type: 'overlay',
        isVisible: Array.from(observables.values()).some((el) => el.value)
      },
      '*'
    )
  },
  { deep: true }
)

export const useModalOverlayObserver = (isVisible: Ref<Boolean>) => {
  observables.value.add(isVisible)

  onUnmounted(() => {
    observables.value.delete(isVisible)
  })
}
