export const useCordova = () => {
  document.body.addEventListener(
    'click',
    function (e) {
      const el = e.target as HTMLElement | null
      const a = el?.closest('a')
      if (!a) return
      const href = a.getAttribute('href')
      if (
        href &&
        (href.startsWith('http') ||
          href.startsWith('tel') ||
          href.startsWith('mailto') ||
          href.startsWith('intent') ||
          href.startsWith('bank'))
      ) {
        e.preventDefault()
        window.parent.postMessage(
          {
            type: 'href',
            value: href
          },
          '*'
        )
      }
    },
    { capture: true }
  )
}
