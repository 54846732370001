<script setup lang="ts">
const appStore = useAppStore()
</script>

<template>
  <div class="flex min-h-screen flex-col gap-3 bg-light dark:bg-black pt-3">
    <div class="container">
      <LocationBar />
    </div>

    <ImportantMessages v-if="appStore.hasMerchantStatuses" />

    <StickyCategories />

    <div class="container min-h-0 grow space-y-5 pb-5">
      <slot></slot>
    </div>

    <StickyFooter>
      <OrderButton />
    </StickyFooter>
  </div>
</template>
