<script setup lang="ts">
/* eslint-disable vue/no-v-html */
const appStore = useAppStore()
</script>

<template>
  <div
    v-if="appStore.settings.merchantStatuses"
    class="container space-y-2.5 text-dark dark:text-white"
  >
    <template v-if="appStore.settings.merchantStatuses.weekend.active">
      <div
        v-if="appStore.settings.merchantStatuses.weekend.message"
        class="flex items-center gap-2.5 rounded-lg bg-indigo-400/30 p-2.5"
      >
        <i-ri-feedback-line class="shrink-0" />
        <div v-html="appStore.settings.merchantStatuses.weekend.message"></div>
      </div>

      <img
        v-if="appStore.settings.merchantStatuses.weekend.image"
        :src="appStore.settings.merchantStatuses.weekend.image"
        alt=""
        class="rounded-lg"
      />
    </template>

    <template v-if="appStore.settings.merchantStatuses.closed.active">
      <div
        v-if="appStore.settings.merchantStatuses.closed.message"
        class="flex items-center gap-2.5 rounded-lg bg-red-400/30 p-2.5"
      >
        <i-ri-lock-fill class="shrink-0" />
        <div v-html="appStore.settings.merchantStatuses.closed.message"></div>
      </div>

      <img
        v-if="appStore.settings.merchantStatuses.closed.image"
        :src="appStore.settings.merchantStatuses.closed.image"
        alt=""
        class="rounded-lg"
      />
    </template>

    <template v-if="appStore.settings.merchantStatuses.disabled.active">
      <div
        v-if="appStore.settings.merchantStatuses.disabled.message"
        class="flex items-center gap-2.5 rounded-lg bg-orange-400/30 p-2.5"
      >
        <i-ri-spam-line class="shrink-0" />
        <div v-html="appStore.settings.merchantStatuses.disabled.message"></div>
      </div>

      <img
        v-if="appStore.settings.merchantStatuses.disabled.image"
        :src="appStore.settings.merchantStatuses.disabled.image"
        alt=""
        class="rounded-lg"
      />
    </template>

    <template v-if="appStore.settings.merchantStatuses.nonWorkingTime.active">
      <div
        v-if="appStore.settings.merchantStatuses.nonWorkingTime.message"
        class="flex items-center gap-2.5 rounded-lg bg-cyan-400/30 p-2.5"
      >
        <i-ri-time-line class="shrink-0" />
        <div v-html="appStore.settings.merchantStatuses.nonWorkingTime.message"></div>
      </div>

      <img
        v-if="appStore.settings.merchantStatuses.nonWorkingTime.image"
        :src="appStore.settings.merchantStatuses.nonWorkingTime.image"
        alt=""
        class="rounded-lg"
      />
    </template>

    <template v-if="appStore.settings.merchantStatuses.busy.active">
      <div
        v-if="appStore.settings.merchantStatuses.busy.message"
        class="flex items-center gap-2.5 rounded-lg bg-orange-500/30 p-2.5"
      >
        <i-ri-fire-fill class="shrink-0" />
        <div v-html="appStore.settings.merchantStatuses.busy.message"></div>
      </div>

      <img
        v-if="appStore.settings.merchantStatuses.busy.image"
        :src="appStore.settings.merchantStatuses.busy.image"
        alt=""
        class="rounded-lg"
      />
    </template>
  </div>
</template>
