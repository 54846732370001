import type { $Fetch, SearchParameters } from 'ofetch'
import sum from 'lodash/sum'
import sortedUniqBy from 'lodash/sortedUniqBy'
import dayjs from 'dayjs'
import {
  calculateItemTotal,
  calculateItemOldTotal,
  parseProduct,
  parsePromocode,
  parseCartRecommendations
} from './utils'
import type {
  Product,
  Category,
  Showcase,
  CategoryWithProducts,
  ProductSize,
  SelectedAddons
} from '@/types/catalog'
import type {
  Cart,
  CartItem,
  CartItemToUpdateQty,
  GetCartParams,
  CartRecommendation
} from '@/types/cart'
import type {
  Address,
  CreateOrderResponse,
  DeliveryArea,
  InitPaymentParams,
  Order,
  OrderDate,
  OrderTime,
  OrderType,
  Payment,
  PaymentCard,
  PickupPoint
} from '@/types/order'
import type { User } from '@/types/user'
import type { Settings, Utm } from '@/types/app'
import type { GiftScale } from '@/types/gift'
import type * as Backend from './types'

export class Api {
  api: $Fetch

  constructor(apiFetch: $Fetch) {
    this.api = apiFetch
  }

  async getProductsByCategoryId(categoryId: string): Promise<Product[]> {
    const response = await this.api<Backend.ApiResponse<Backend.LoadItemByCategory>>(
      'singlemerchant/api/loadItemByCategory',
      {
        params: {
          cat_id: categoryId
        }
      }
    )
    return response.details.data.map((item): Product => parseProduct(item))
  }

  async getCategories(): Promise<Category[]> {
    const response = await this.api<Backend.ApiResponse<Backend.LoadCategory>>(
      'singlemerchant/api/loadCategory'
    )

    return response.details.data.map(
      (item): Category => ({
        id: item.cat_id,
        name: item.category_name,
        slug: item.category_code
      })
    )
  }

  async getShowcase(): Promise<Showcase> {
    const response = await this.api<Backend.ApiResponse<Backend.GetHomeProducts>>(
      'singlemerchant/api/getHomeProducts',
      {
        params: {
          full: true
        }
      }
    )

    return {
      categories: response.details.data
        .map(
          (item): CategoryWithProducts => ({
            id: item.id,
            name: item.name,
            slug: item.code,
            products: Object.values(item.items).map((item): Product => parseProduct(item))
          })
        )
        .filter((category) => {
          return category.products?.length
        })
    }
  }

  async getCart(params?: GetCartParams): Promise<Cart> {
    const response = await this.api<Backend.ApiResponse<Backend.LoadCart>>(
      'singlemerchant/api/loadCart',
      {
        params: {
          transaction_type: params?.orderType,
          delivery_date: params?.date,
          delivery_time: params?.time
        }
      }
    )

    return {
      identifier: response.get.device_id,
      items: response.details.data.item.map(
        (item, index): CartItem => ({
          id: item.item_id,
          index: index,
          name: item.item_name,
          qty: Number(item.qty),
          price: Number(item.discounted_price) || 0,
          total: calculateItemTotal(item),
          ...(item.discount && {
            oldPrice: Number(item.normal_price),
            oldTotal: calculateItemOldTotal(item)
          }),
          image: item.photo,
          size: item.size_words ? { name: item.size_words } : undefined,
          addons:
            item.new_sub_item &&
            Object.entries(item.new_sub_item).map(([groupName, addons]) => ({
              groupName: groupName,
              addons: addons.map((addon) => ({
                name: addon.addon_name,
                qty: Number(addon.addon_qty) / Number(item.qty),
                price: Number(addon.addon_price) || 0
              }))
            })),
          isGift:
            item.discount === 'dish_gift' ||
            item.discount === 'sale_gift' ||
            item.size_words === '(Товар в подарок)'
        })
      ),
      itemsPrice: Number(response.details.basket_total),
      discount: Number(response.details.basket_discount),
      subTotal: Number(response.details.basket_total_with_discount),
      deliveryCost: Number(response.details.data.total.delivery_charges),
      total: Number(response.details.data.total.total),
      errors: response.details.cart_error,
      bonusesDown: Number(response.details.cart_details.points_apply),
      bonusesUp: Number(response.details.points_earn),
      bonusesBalance: response.details.available_points,
      bonusesLabel: response.details.available_points_label,
      bonusesMax: response.details.bonuses_max,
      promocode: parsePromocode(response.details.cart_details.voucher_details)?.name,
      promotions: response.details.sales_control_output || [],
      selectedGift:
        (response.details.gift_cart &&
          !Array.isArray(response.details.gift_cart) && {
            id: response.details.gift_cart.gift_featured.item_id,
            name: response.details.gift_cart.gift_featured.gift_desc.presents_item_name,
            image: response.details.gift_cart.gift_featured.gift_desc.presents_vendor_image,
            description: response.details.gift_cart.gift_featured.gift_desc.presents_description,
            available: true
          }) ||
        undefined,
      stopList:
        response.details.stop_list_box?.length && response.details.stop_list_msg
          ? {
              title: response.details.stop_list_msg,
              messages: response.details.stop_list_box
            }
          : null
    }
  }

  async removeCart() {
    await this.api<Backend.ApiResponse>('singlemerchant/api/clearCart')
  }

  async addToCart(
    product: Product,
    qty: number,
    size?: ProductSize | null,
    addons?: SelectedAddons | null,
    from: string | null = null
  ): Promise<void> {
    const body = {
      item_id: product.id,
      price: size ? `${size.price}|${size.name}|${size.id}` : product.price,
      qty: qty
    } as Backend.RequestBodyAddToCart

    if (from) {
      body.from = from
    }

    if (addons) {
      Object.entries(addons).forEach(([addonGroupId, addons]) => {
        const currentAddonGroup = product.addons?.find((group) => group.id === addonGroupId)

        if (currentAddonGroup?.min && currentAddonGroup.min > sum(Object.values(addons))) {
          throw new Error(
            currentAddonGroup.min === 1
              ? `Пожалуйста, выберите "${currentAddonGroup.name}"`
              : `Пожалуйста, выберите "${currentAddonGroup.name}". Мин. кол-во - ${currentAddonGroup.min}`
          )
        }

        body.addon_qty ??= {}
        body.sub_item ??= {}
        body.addon_qty[addonGroupId] = []
        body.sub_item[addonGroupId] = []

        Object.entries(addons).forEach(([addonId, addonQty]) => {
          if (qty > 0 && body.addon_qty && body.sub_item && addonQty) {
            const addon = product.addons
              ?.find((group) => group.id === addonGroupId)
              ?.addons.find((item) => item.id === addonId)

            if (addon) {
              body.sub_item[addonGroupId].push(`${addon.id}|${addon.price}|${addon.name}`)
              body.addon_qty[addonGroupId].push(addonQty * qty)
            }
          }
        })
      })
    }

    await this.api('singlemerchant/api/addToCart', {
      method: 'POST',
      body: body
    })
  }

  async getCartRecommendations(): Promise<Array<CartRecommendation>> {
    const response = await this.api<Backend.ApiResponse<Backend.CartRecommendations>>(
      'singlemerchant/api/recommendCart'
    )
    return parseCartRecommendations(response.details)
  }

  async updateCartItemQty(item: CartItemToUpdateQty) {
    await this.api('singlemerchant/api/updateCart', {
      method: 'POST',
      body: {
        qty: item.qty,
        row: item.index
      }
    })
  }

  async getProductById(id: string): Promise<Product> {
    const response = await this.api<Backend.ApiResponse<Backend.LoadItemDetails>>(
      'singlemerchant/api/loadItemDetails',
      {
        params: {
          item_id: id
        }
      }
    )

    return {
      ...parseProduct(response.details.data),
      inStock: response.details.left_items ? Number(response.details.left_items) : null
    }
  }

  async getPickupPoints(): Promise<PickupPoint[]> {
    try {
      const response = await this.api<Backend.ApiResponse<Backend.PointDelivery>>(
        'singlemerchant/api/pointDelivery'
      )

      return response.details.data.map((item) => ({
        id: item.id,
        name: item.place_name,
        isDefault: item.default_point,
        isDineIn: item.is_dine_in,
        addressLine: item.data?.address
      }))
    } catch {
      return []
    }
  }

  async getUser(): Promise<User> {
    const response = await this.api<Backend.ApiResponse<Backend.GetUserProfile>>(
      'singlemerchant/api/getUserProfile'
    )

    return {
      phoneNumber: response.details.data.contact_phone,
      name: response.details.data.first_name,
      birthday: response.details.data.birthday
    }
  }

  async getAddressList(): Promise<Address[]> {
    try {
      const response = await this.api<Backend.ApiResponse<Backend.GetAddressBookList>>(
        'singlemerchant/api/getAddressBookList'
      )

      return response.details.data.map((item) => ({
        id: item.id,
        name: item.location_name,
        city: item.city,
        street: item.street,
        house: item.state,
        building: item.housing,
        entrance: item.pod,
        doorPhone: item.domofon,
        floor: item.et,
        apartment: item.zipcode,
        addressLine: item.address_line || [item.street, item.state].filter(Boolean).join(', '),
        coords: item.coords && JSON.parse(item.coords),
        comment: item.comment
      }))
    } catch {
      return []
    }
  }

  async getAddressById(id: string): Promise<Address> {
    const response = await this.api<Backend.ApiResponse<Backend.GetAddressBook>>(
      'singlemerchant/api/getAddressBook',
      {
        params: {
          id: id
        }
      }
    )

    return {
      id: response.details.id,
      name: response.details.location_name,
      city: response.details.city,
      street: response.details.street,
      house: response.details.state,
      building: response.details.housing,
      entrance: response.details.pod,
      doorPhone: response.details.domofon,
      floor: response.details.et,
      apartment: response.details.zipcode,
      addressLine:
        response.details.address_line ||
        [response.details.street, response.details.state].filter(Boolean).join(', '),
      coords: response.details.coords && JSON.parse(response.details.coords),
      comment: response.details.comment
    }
  }

  async saveAddress(address: Address) {
    await this.api<Backend.ApiResponse>('singlemerchant/api/saveAddressBook', {
      params: {
        book_id: address.id,
        location_name: address.name,
        address_line: address.addressLine,
        city: address.city,
        street: address.street,
        state: address.house,
        housing: address.building,
        pod: address.entrance,
        domofon: address.doorPhone,
        et: address.floor,
        zipcode: address.apartment,
        coords: JSON.stringify(address.coords),
        comment: address.comment
      }
    })
  }

  async deleteAddress(address: Address) {
    await this.api<Backend.ApiResponse>('singlemerchant/api/deleteAddressBook', {
      params: {
        id: address.id
      }
    })
  }

  async getAppSettings(): Promise<Settings> {
    const details = (
      await this.api<Backend.ApiResponse<Backend.GetAppSettings>>(
        'singlemerchant/api/getAppSettings'
      )
    ).details

    return {
      city: details.city,
      merchantId: details.merchant_code?.replace(/\D/g, ''),
      deliveryMapFileUrl: details.map_with_delivery_zones,
      yandexMapApiKey: details.merchant_yandex_api_key,
      tracardiSourceId: details.magic_on,
      asapOnly: details.disable_time === '1',
      merchantStatuses: {
        weekend: {
          active: details.shop_settings.closed_holiday_enabled,
          message: details.shop_settings.closed_holiday_msg,
          image: details.shop_settings.closed_holiday_img
        },
        closed: {
          active: details.shop_settings.closed_shop_enabled,
          message: details.shop_settings.closed_shop_msg,
          image: details.shop_settings.closed_shop_img
        },
        disabled: {
          active: details.shop_settings.disabled_order_enabled,
          message: details.shop_settings.disabled_order_msg,
          image: details.shop_settings.disabled_order_img
        },
        nonWorkingTime: {
          active: details.shop_settings.merchant_break,
          message: details.shop_settings.merchant_break_msg,
          image: details.shop_settings.merchant_break_img
        },
        busy: {
          active: Boolean(
            (details.shop_settings.kitchen_show_now && details.shop_settings.kitchen_pressure) ||
              details.is_pressure_time
          ),
          message: details.shop_settings.kitchen_text,
          image: details.shop_settings.kitchen_img
        }
      },
      orderFields: {
        persons: {
          label: details.fields_addons_settings?.find((item) => item.field === 'count_person')
            ?.field_name,
          active: Boolean(details.fields_addons.count_person)
        },
        callMe: {
          label: details.fields_addons.call_me,
          active: Boolean(details.fields_addons.call_me)
        }
      },
      currencySymbol: details.currency_symbol,
      bonusesEnabled: Boolean(details.has_pts),
      mutualZoneSettings: details.divided_time === 'off'
    }
  }

  async getDeliveryArea(addressLine: string, preparatory: boolean = false): Promise<DeliveryArea> {
    const response = await this.api<Backend.ApiResponse<Backend.GeoAdressDelivery>>(
      'singlemerchant/api/geoAdressDelivery',
      {
        params: {
          query: addressLine,
          ...(preparatory && { preparatory_check: 'true' })
        }
      }
    )

    if (response.msg === 'error' && !response.details.zone) {
      throw new Error(response.details.text || 'По выбранному адресу доставка не осуществляется')
    }

    return {
      name: response.details.zone,
      outerName: response.details.out_text,
      deliveryCost: Number(response.details.delivery_price) || 0,
      freeDelivery: Number(response.details.free_delivery) || 0,
      minOrder: Number(response.details.min_price) || 0,
      deliveryTime: response.details.delivery_time,
      availablePayments: response.details.payments?.map((item) => ({
        code: item.payment_code,
        label: item.payment_name
      }))
    }
  }

  async createOrder(order: Order, utm?: Utm): Promise<CreateOrderResponse> {
    const comment =
      order.type.code === 'delivery' && order.address
        ? [order.address.comment, order.comment].filter(Boolean).join(' | ')
        : order.comment

    if (order.type.code === 'delivery') {
      if (!order.address) throw new Error('Отсутсвует выбранный адрес')

      await this.api<Backend.ApiResponse>('singlemerchant/api/setDeliveryAddress', {
        params: {
          street: order.address.street,
          state: order.address.house,
          zipcode: order.address.apartment,
          pod: order.address.entrance,
          et: order.address.floor,
          domofon: order.address.doorPhone,
          delivery_instruction: comment,
          housing: order.address.building,
          count_person: order.persons,
          contact_phone: order.contactPhone
        }
      })
    }

    const params: SearchParameters = {
      transaction_type: order.type.code,
      contact_name: order.contactName,
      contact_phone: order.contactPhone,
      delivery_asap: order.asap ? 'true' : '',
      delivery_date: order.asap ? dayjs().format('YYYY-MM-DD') : order.date,
      delivery_time: order.asap ? '' : order.time,
      payment_provider: order.payment.code,
      comment: comment,
      point_delivery:
        (order.type.code === 'dinein' ? order.dineInPoint?.id : order.pickupPoint?.id) || '',
      count_person: order.persons,
      order_change: order.changeCash,
      dinein_table_number: order.tableNumber,
      call_me: order.callMe ? 'true' : ''
    }

    if (utm) {
      Object.entries(utm).forEach(([key, value]) => {
        params[`utm_${key}`] = value
      })
    }

    const response = await this.api<Backend.ApiResponse<Backend.PayNow>>(
      'singlemerchant/api/payNow',
      {
        params
      }
    )

    return {
      id: response.details.order_id,
      message: response.msg,
      redirect: response.details.redirect_url,
      recurrent: response.details.recurrent,
      pointName: response.details.place_name
    }
  }

  async applyBonuses(amount: number): Promise<void> {
    await this.api<Backend.ApiResponse>('singlemerchant/api/applyRedeemPoints', {
      params: {
        points: amount
      }
    })
  }

  async removeBonuses(): Promise<void> {
    await this.api<Backend.ApiResponse>('singlemerchant/api/removePoints')
  }

  async getPaymentList(OrderTypeCode?: Backend.OrderTypeCode): Promise<Payment[]> {
    const response = await this.api<Backend.ApiResponse<Backend.LoadPaymentList>>(
      'singlemerchant/api/loadPaymentList',
      {
        params: {
          transaction_type: OrderTypeCode
        }
      }
    )

    return response.details.data.map((item) => ({
      code: item.payment_code,
      label: item.payment_name,
      needChangeCash: item.order_change === '1'
    }))
  }

  async applyPromocode(promocode: string): Promise<void> {
    await this.api<Backend.ApiResponse>('singlemerchant/api/applyVoucher', {
      params: {
        voucher_name: promocode
      }
    })
  }

  async removePromocode(): Promise<void> {
    await this.api<Backend.ApiResponse>('singlemerchant/api/removeVoucher')
  }

  async getDateList(): Promise<OrderDate[]> {
    const response = await this.api<Backend.ApiResponse<Backend.DeliveryDateList>>(
      'singlemerchant/api/deliveryDateList'
    )

    return Object.entries(response.details.data).map(([key, value]) => ({
      label: value,
      value: key
    }))
  }

  async getTimeList(date?: string): Promise<OrderTime[]> {
    const response = await this.api<Backend.ApiResponse<Backend.DeliveryTimeList>>(
      'singlemerchant/api/deliveryTimeList',
      {
        params: {
          delivery_date: date
        }
      }
    )

    return response.details.data.map((item) => ({
      label: item,
      value: item
    }))
  }

  async getOrderTypes(): Promise<OrderType[]> {
    const response = await this.api<Backend.ApiResponse<Backend.ServicesList>>(
      'singlemerchant/api/servicesList'
    )

    return Object.entries(response.details.data).map<OrderType>(([code, label]) => ({
      label: label,
      code: code as Backend.OrderTypeCode
    }))
  }

  async getOrderById(id: string) {
    const response = await this.api<Backend.ApiResponse<Backend.Order>>(
      'singlemerchant/rest/order/get',
      {
        params: { id }
      }
    )

    return response.details
  }

  async getCardList(): Promise<PaymentCard[]> {
    const response = await this.api<Backend.ApiResponse<Backend.GetCardList>>(
      'singlemerchant/api/getCardList'
    )

    return Object.values(response.details.card_list).map((card) => ({
      id: card.CardId,
      rebillId: card.RebillId,
      pan: card.Pan,
      expDate: card.ExpDate
    }))
  }

  async initPayment(params: InitPaymentParams) {
    const response = await this.api<Backend.ApiResponse<Backend.InitPayment>>(
      'singlemerchant/api/initPayment',
      {
        params: {
          order_id: params.orderId,
          recurrent: params.recurrent,
          RebillId: params.rebillId
        }
      }
    )

    return response.details
  }

  async removeCard(cardId: string): Promise<unknown> {
    const response = await this.api<Backend.ApiResponse<Backend.RemoveCard>>(
      'singlemerchant/api/removeCard',
      {
        params: { CardId: cardId }
      }
    )

    return response
  }

  async getGiftScale(): Promise<GiftScale | null> {
    try {
      const response = await this.api<Backend.ApiResponse<Backend.GiftList>>(
        'singlemerchant/api/giftList'
      )

      return {
        items: response.details.items.map((item) => ({
          id: item.item_id,
          name: item.name,
          image: item.photo,
          available: Boolean(item.available),
          description: item.available_text
        })),
        currentLevel: response.details.context.current_level,
        percent: response.details.context.delta_level,
        checkpoints: sortedUniqBy(
          response.details.items.map((item) => ({
            value: item.presents_summ,
            percent: item.progress_percent,
            sum: item.presents_to
          })),
          'value'
        )
      }
    } catch {
      return null
    }
  }

  async selectGift(giftId: string) {
    return await this.api<Backend.ApiResponse>('singlemerchant/api/addGiftToCart', {
      method: 'POST',
      body: { item_id: giftId }
    })
  }

  async removeGift(giftId: string) {
    return await this.api<Backend.ApiResponse>('singlemerchant/api/demolishGiftFromCart', {
      method: 'POST',
      body: { item_id: giftId }
    })
  }
}
