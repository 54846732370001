<script setup lang="ts">
const giftStore = useGiftStore()
const route = useRoute()
</script>

<template>
  <div class="sticky bottom-0 z-10 mt-auto shrink-0 space-y-3">
    <div v-if="giftStore.giftScaleAvailable && route.name === 'Index'" class="container">
      <GiftScale />
    </div>

    <div class="overflow-hidden bg-white shadow-base dark:bg-black">
      <div class="container pb-5 pt-2.5">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
