import type { GiftCheckpoint, GiftItem, GiftScale } from '@/types/gift'

export const useGiftStore = defineStore('gift', {
  state: () => ({
    giftScale: null as GiftScale | null,
    needSelectGiftModal: false,
    modalWasShown: false
  }),

  getters: {
    giftScaleAvailable(state): boolean {
      return Boolean(state.giftScale && state.giftScale.items.length > 0)
    },
    currentCheckpoint(state): GiftCheckpoint | null {
      return state.giftScale?.checkpoints[state.giftScale.currentLevel] || null
    },
    selectedGift(): GiftItem | null {
      const cartStore = useCartStore()
      return cartStore.cart?.selectedGift || null
    },
    needSelectGift(state): boolean {
      return (
        Boolean(!this.selectedGift && state.giftScale?.items.some((item) => item.available)) &&
        !state.modalWasShown
      )
    }
  },

  actions: {
    async fetchGiftScale() {
      this.giftScale = await useApi().getGiftScale()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGiftStore, import.meta.hot))
}
