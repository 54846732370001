import type { Product } from '@/types/catalog'
import { $fetch } from 'ofetch'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { klona } from 'klona/json'

dayjs.extend(isBetween)

// "eb849197128030709218468e0c72f6f3"
// "a0bcfa479d740e40e22b899958be28b3"
// "a553e49084e0a0d474c0b4151bda3f4f"
// "13a4bf74423b2c575f07b15ba04b07fa"
// "416bb1010e994780e2e35d075ade49e0"

type AutomationRule = {
  components: Component[]
}

interface HappyHour {
  condition: Condition
  actions: Action[]
}

type Component =
  | {
      type: 'action'
      data: Action[]
      children?: Component[]
    }
  | {
      type: 'condition'
      data: Condition[]
      children?: Component[]
    }

interface Action {
  name: string
  description: string
  params: unknown[]
}

interface Condition {
  name: string
  description: string
  params: unknown[]
}

const happyHoursList = ref<HappyHour[]>()

export async function fetchHappyHoursList() {
  const merchantKey = sessionStorage.getItem('merchantKey')

  try {
    const automationRules = await $fetch<AutomationRule[]>('api/automation-rules', {
      baseURL: 'https://app.resto-loyalty.ru',
      responseType: 'json',
      query: { merchant_key: merchantKey }
    })

    happyHoursList.value = getHappyHours(automationRules)
  } catch (error) {
    console.warn(error)
  }
}

function getHappyHours(rules: AutomationRule[]) {
  const finded: HappyHour[] = []

  rules.forEach((rule) => {
    findHappyHours(rule.components)
  })

  function findHappyHours(components: Component[]) {
    components.forEach((component) => {
      if (component?.type !== 'condition') return

      component.data.forEach((condition: Condition) => {
        if (isHappyHours(condition)) {
          component.children

          finded.push({
            condition: condition,
            actions: component.children ? findDiscountActions(component.children[1]) : []
          })
        }
      })

      if (component.children) {
        findHappyHours(component.children)
      }
    })
  }

  function isHappyHours(condition: Condition): condition is Condition & { name: 'HappyHour' } {
    return condition.name === 'HappyHour'
  }

  function findDiscountActions(component: Component) {
    const actions: Action[] = []

    if (component.type === 'action') {
      actions.push(
        ...component.data.filter((action) => {
          // "OrderDiscount"
          return ['CategoryDiscount', 'ProductDiscount'].includes(action.name)
        })
      )

      if (component.children?.length === 1) {
        actions.push(...findDiscountActions(component.children[0]))
      }
    }

    return actions
  }

  return finded
}

function minutes(value: number) {
  return dayjs().minute(value).format('mm')
}

function getDayOfWeek() {
  const day = dayjs().day() - 1
  return day < 0 ? 6 : day
}

export function useHappyHours(product: Product) {
  const productHappyHours = getProductHappyHours()

  function getProductHappyHours() {
    if (!happyHoursList.value) return null

    for (const happyHour of happyHoursList.value) {
      for (const action of happyHour.actions) {
        let finded: boolean = false

        if (action.name === 'ProductDiscount') {
          const productIds = (action.params[1] as Array<string | number>).map((id) => String(id))
          finded =
            String(action.params[0]) === product.categoryId && productIds.includes(product.id)
        }

        if (action.name === 'CategoryDiscount') {
          const categoryIds = (action.params[0] as Array<string | number>).map((id) => String(id))
          finded = Boolean(product.categoryId && categoryIds.includes(product.categoryId))
        }

        if (finded) {
          return {
            action,
            condition: happyHour.condition
          }
        }
      }
    }

    return null
  }

  function getLabel() {
    try {
      const settings = productHappyHours
      const discount = getDiscount()
      if (!settings || !discount) return null

      const start = settings.condition.params[0] as [number, number]
      const end = settings.condition.params[1] as [number, number]

      return {
        description: settings.condition.description || 'Счастливые часы',
        time: `с ${start[0]}:${minutes(start[1])} до ${end[0]}:${minutes(end[1])}`,
        discount: `${discount.value}${discount.symbol}`
      }
    } catch {
      return null
    }
  }

  function getDiscount() {
    const settings = productHappyHours
    if (!settings || !discountIsActive()) return null

    const valueIndex = settings.action.name === 'CategoryDiscount' ? 1 : 2
    const typeIndex = settings.action.name === 'CategoryDiscount' ? 2 : 3
    const applyToAddonsIndex = settings.action.name === 'CategoryDiscount' ? 3 : 4

    const discountValue = settings.action.params[valueIndex] as number
    const discountType = settings.action.params[typeIndex] as string
    const discountSymbol = discountType === 'percent' ? '%' : ' ₽'
    const applyToAddons =
      (settings.action.params[applyToAddonsIndex] as boolean) && discountType === 'percent'

    return {
      value: discountValue,
      type: discountType,
      symbol: discountSymbol,
      applyToAddons: applyToAddons
    }
  }

  function discountIsActive() {
    if (!productHappyHours) return false

    const weekDays = productHappyHours.condition.params[2] as boolean[]
    const dayOfWeek = getDayOfWeek()

    const start = productHappyHours.condition.params[0] as [number, number]
    const end = productHappyHours.condition.params[1] as [number, number]

    const startDate = dayjs().hour(start[0]).minute(start[1])
    const endDate = dayjs().hour(end[0]).minute(end[1])

    if (weekDays[dayOfWeek] && dayjs().isBetween(startDate, endDate, 'minute', '[)')) {
      return true
    }

    return false
  }

  function processProduct() {
    try {
      const discount = getDiscount()

      if (!discount) return product

      const productCopy = klona(product)

      productCopy.oldPrice = product.price
      productCopy.price =
        discount.type === 'percent'
          ? (product.price / 100) * (100 - discount.value)
          : product.price - discount.value

      if (productCopy.sizes) {
        productCopy.sizes.forEach((size) => {
          if (!size.price) return
          size.oldPrice = size.price
          size.price =
            discount.type === 'percent'
              ? (size.price / 100) * (100 - discount.value)
              : size.price - discount.value
        })
      }

      if (discount.applyToAddons && productCopy.addons) {
        productCopy.addons.forEach((addonGroup) => {
          addonGroup.addons.forEach((addon) => {
            if (!addon.price) return
            addon.price =
              discount.type === 'percent'
                ? (addon.price / 100) * (100 - discount.value)
                : addon.price - discount.value
          })
        })
      }

      return productCopy
    } catch {
      return product
    }
  }

  return {
    happyHoursList,
    fetchHappyHoursList,
    getProductHappyHours,
    getDayOfWeek,
    getLabel,
    processProduct,
    getDiscount
  }
}
