<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value: number
    disabled?: boolean
  }>(),
  {
    disabled: false
  }
)

defineEmits<{
  (e: 'plus'): void
  (e: 'minus'): void
}>()
</script>

<template>
  <div class="flex items-center gap-1 rounded-lg">
    <button
      class="h-full rounded-md bg-primary/20 px-2.5 py-1 text-primary transition disabled:opacity-70"
      :disabled="disabled"
      @click="$emit('minus')"
    >
      <i-ri-subtract-line />
    </button>
    <span class="min-w-[2rem] grow text-center text-lg leading-none text-dark dark:text-neutral-200">
      {{ props.value }}
    </span>
    <button
      class="h-full rounded-md bg-primary/20 px-2.5 py-1 text-primary transition disabled:opacity-70"
      :disabled="disabled"
      @click="$emit('plus')"
    >
      <i-ri-add-line />
    </button>
  </div>
</template>
