<script setup lang="ts">
import CatalogLayout from '@/layouts/CatalogLayout.vue'
import GuestLayaout from '@/layouts/GuestLayaout.vue'
import { authToken } from '@/composables/api'

useTitle('Главная')
const { showcase } = storeToRefs(useCatalogStore())
</script>

<template>
  <component :is="authToken ? CatalogLayout : GuestLayaout">
    <CatalogSearch v-if="showcase?.categories">
      <ProductCategory
        v-for="category in showcase?.categories"
        :id="`category-${category.id}`"
        :key="category.id"
        :category="category"
      >
        <ProductCard v-for="product in category.products" :key="product.id" :product="product" />
      </ProductCategory>
    </CatalogSearch>
  </component>
</template>
