<script setup lang="ts">
const { appReady, loading } = storeToRefs(useAppStore())

useUtmStore().setUtmFromQuery()
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <RouterView v-slot="{ Component }">
      <component :is="Component" v-if="appReady"></component>

      <div v-else class="fixed inset-0 grid h-screen place-items-center">
        <i-ri-loader-line class="animate-spin text-2xl text-dark dark:text-neutral-200" />
      </div>
    </RouterView>

    <OpacityTransition>
      <div
        v-if="loading"
        class="fixed inset-0 z-50 grid h-screen place-items-center bg-gray-dark/10"
      >
        <i-ri-loader-line class="animate-spin text-2xl text-dark dark:text-neutral-200" />
      </div>
    </OpacityTransition>
  </div>
</template>
