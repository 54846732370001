<template>
  <Transition
    enter-from-class="opacity-0"
    enter-active-class="transition ease-in-out"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-active-class="transition ease-in-out"
    leave-to-class="opacity-0"
  >
    <slot />
  </Transition>
</template>
