<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperInstance } from 'swiper/types'

const { showcase, search } = storeToRefs(useCatalogStore())

const { activeCategory } = useCatalogObserver()

const activeCategoryIndex = computed(() => {
  if (!showcase.value) return -1

  return showcase.value.categories.findIndex((category) => category.id === activeCategory.value?.id)
})

const swiper = shallowRef<SwiperInstance | null>(null)

watchDebounced(
  activeCategoryIndex,
  (index) => {
    if (index >= 0 && index !== swiper.value?.activeIndex) {
      swiper.value?.slideTo(index, 1000)
    }
  },
  { debounce: 100 }
)

function swiperReady(swiperInstance: SwiperInstance) {
  swiper.value = swiperInstance
}

async function moveTo(categoryId: string) {
  search.value = ''
  await nextTick()
  const el = document.getElementById(`category-${categoryId}`)
  el?.scrollIntoView()
}

const categoryFromQuery = useRouteQuery<string>('category')

onMounted(() => {
  whenever(
    categoryFromQuery,
    (query) => {
      const category = showcase.value?.categories.find(
        (item) => item.id == query || item.slug == query
      )

      if (category) {
        moveTo(category.id)
      }
    },
    { immediate: true }
  )
})
</script>

<template>
  <Swiper
    slides-per-view="auto"
    :initial-slide="activeCategoryIndex || 0"
    :space-between="8"
    :slide-to-clicked-slide="true"
    :centered-slides="true"
    :centered-slides-bounds="true"
    class="container sticky top-0 min-w-0 bg-light py-3 dark:bg-black"
    @swiper="swiperReady"
  >
    <SwiperSlide v-for="category in showcase?.categories" :key="category.id" class="w-auto">
      <a
        :href="`#category-${category.id}`"
        class="inline-block shrink-0 rounded-md bg-white px-5 py-2 text-gray-dark transition dark:bg-neutral-900 dark:text-neutral-300"
        :class="{ active: activeCategory?.id === category.id }"
        @click.prevent="moveTo(category.id)"
      >
        {{ category.name }}
      </a>
    </SwiperSlide>
  </Swiper>
</template>

<style scoped>
.active {
  @apply border-transparent bg-primary text-white;
}
</style>
