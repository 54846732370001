import type {
  Cart,
  CartItem,
  CartItemToUpdateQty,
  GetCartParams,
  CartRecommendation
} from '@/types/cart'
import type { Product, ProductSize, SelectedAddons } from '@/types/catalog'
import StopListToast from '@/components/StopListToast.vue'
import { useEcommerce } from '@/composables/ecommerce'

export const useCartStore = defineStore('cart', () => {
  const api = useApi()
  const orderStore = useOrderStore()

  const cart = ref<Cart | null>(null)
  const cartRecommendations = ref<Array<CartRecommendation> | null>(null)

  watch(cart, (newCart, oldCart) => {
    const newItems = newCart?.items || []
    const oldItems = oldCart?.items || []

    if (newItems.length && JSON.stringify(newItems) !== JSON.stringify(oldItems)) {
      useEcommerce().bus.emit({
        name: 'cartItemsChanges',
        data: {
          products: newItems.map((item) => {
            return {
              id: item.id,
              name: item.name,
              price: item.price,
              quantity: item.qty
            }
          })
        }
      })
    }
  })

  async function fetchCart() {
    try {
      const orderStore = useOrderStore()

      const params: GetCartParams = {
        orderType: useOrderStore().selectedOrderType?.code
      }

      if (!orderStore.asap) {
        params.date = orderStore.selectedDate?.value
        params.time = orderStore.selectedTime?.value
      }

      cart.value = await api.getCart(params)

      if (cart.value.stopList) {
        useToast().error({
          component: StopListToast,
          props: {
            title: cart.value.stopList.title,
            messages: cart.value.stopList.messages
          }
        })
      }
    } catch {
      cart.value = null
    } finally {
      const giftStore = useGiftStore()
      giftStore.fetchGiftScale()
    }
  }

  async function removeCart() {
    await api.removeCart()
    await fetchCart()
  }

  async function addToCart(
    product: Product,
    qty: number,
    size?: ProductSize | null,
    addons?: SelectedAddons | null,
    from: string | null = null
  ) {
    await api.addToCart(product, qty, size, addons, from)
    await Promise.all([fetchCart(), fetchCartRecommendations()])
  }

  async function fetchCartRecommendations() {
    try {
      cartRecommendations.value = await api.getCartRecommendations()
    } catch (error) {
      cartRecommendations.value = null
    }
  }

  async function updateCartItemQty(item: CartItemToUpdateQty) {
    await api.updateCartItemQty(item)
    await Promise.all([fetchCart(), fetchCartRecommendations()])
    if (!cart.value) {
      useEcommerce().bus.emit({
        name: 'cartEmpty'
      })
    }
  }

  const cartItemsById = computed(() => {
    return cart.value?.items.reduce(
      (result, item) => {
        if (item.size?.name === '(Товар в подарок)') return result
        result[item.id] = result[item.id] || []
        result[item.id].push(item)
        return result
      },
      {} as {
        [id: string]: Array<CartItem>
      }
    )
  })

  const qtyById = computed(() => {
    return cart.value?.items.reduce(
      (result, item) => {
        if (item.size?.name === '(Товар в подарок)') return result
        result[item.id] = result[item.id] || 0
        result[item.id] += item.qty
        return result
      },
      {} as {
        [id: string]: number
      }
    )
  })

  const canUseCart = computed<boolean>(() => {
    if (
      (orderStore.isDelivery && orderStore.selectedAddress) ||
      (orderStore.isPickup && orderStore.selectedPickupPoint) ||
      (orderStore.isDineIn && orderStore.selectedDineInPoint)
    ) {
      return true
    } else {
      return false
    }
  })

  return {
    cart,
    cartRecommendations,
    cartItemsById,
    qtyById,
    canUseCart,
    fetchCart,
    addToCart,
    updateCartItemQty,
    removeCart,
    fetchCartRecommendations
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
