import type { Item, PriceData, AddonGroupData, LoadCart, CartRecommendations } from './types'
import minBy from 'lodash/minBy'
import type { Addon, Product, Sticker } from '@/types/catalog'
import type { CartRecommendation, RecommendationItem } from '@/types/cart'

export function normalizeMeasure(value?: string): string | undefined {
  if (value === '0' || !value) {
    return undefined
  } else {
    return value
  }
}

export function getСheapest(priceData?: PriceData[]): PriceData | undefined {
  return minBy(priceData, 'price')
}

export function getCurrentPrice(priceData?: PriceData): number {
  return Number(priceData?.discount_price || priceData?.price) || 0
}

export function getOldPrice(priceData?: PriceData): number | undefined {
  return (priceData?.discount_price && priceData.discount && Number(priceData.price)) || undefined
}

export function parseProduct(item: Item): Product {
  const cheapest = getСheapest(item.prices)

  return {
    id: item.item_id,
    name: item.item_name,
    image: item.photo,
    description: item.item_description,
    weight: Number(item.item_massa) || undefined,
    measure: normalizeMeasure(item.unit),
    price: getCurrentPrice(cheapest),
    oldPrice: getOldPrice(cheapest),
    sizes: item.prices
      ?.map((item) => ({
        id: String(item.size_id),
        name: String(item.size),
        price: getCurrentPrice(item),
        oldPrice: getOldPrice(item),
        weight: Number(item.size_weight) || undefined
      }))
      .filter((item) => item.id && item.name),
    hasAddons: item.addon_item === '1' || Array.isArray(item.addon_item),
    proteins: item.item_protein,
    fats: item.item_fats,
    carbohydrates: item.item_carbohydrates,
    calories: item.item_calories,
    addons: Array.isArray(item.addon_item) ? parseAddons(item.addon_item) : undefined,
    notAvailable: item.not_available === '2' || item.not_available === 2,
    stickers: parseStickers(item),
    categoryId: Array.isArray(item.category) ? item.category[0] : item.category
  }
}

export function parseAddons(groups: AddonGroupData[]): Product['addons'] {
  return groups.map((group) => ({
    id: group.subcat_id,
    name: group.subcat_name,
    min: getAddonGroupMin(group),
    max: getAddonGroupMax(group),
    addons: group.sub_item.map(
      (item): Addon => ({
        id: item.sub_item_id,
        name: item.sub_item_name,
        image: item.photo,
        price: Number(item.price) || undefined,
        disabled: Boolean(item.not_available)
      })
    )
  }))
}

export function getAddonGroupMin(group: AddonGroupData): number | undefined {
  if (Number(group.multi_option_min_val)) {
    return Number(group.multi_option_min_val)
  }

  if (group.require_addons === '2') {
    return 1
  }
}

export function getAddonGroupMax(group: AddonGroupData): number | undefined {
  if (Number(group.multi_option_val)) {
    return Number(group.multi_option_val)
  }

  if (group.multi_option === 'one') {
    return 1
  }
}

export function calculateItemTotal(item: LoadCart['data']['item'][0]): number {
  return Number(item.total_price) + calculateCartItemAddonsPrice(item.sub_item) || 0
}

export function calculateItemOldTotal(item: LoadCart['data']['item'][0]): number {
  return Number(item.normal_price) * Number(item.qty) + calculateCartItemAddonsPrice(item.sub_item)
}

export function calculateCartItemAddonsPrice(
  addons: LoadCart['data']['item'][0]['sub_item']
): number {
  return (
    addons?.reduce((result, item) => {
      result += Number(item.addon_qty) * Number(item.addon_price) || 0
      return result
    }, 0) || 0
  )
}

export function parsePromocode(jsonString?: string) {
  if (!jsonString) return

  const promocodeData = JSON.parse(jsonString)

  return {
    id: String(promocodeData.voucher_id),
    name: String(promocodeData.voucher_name)
  }
}

export function parseStickers(item: Item): Sticker[] {
  if (!item.stickers) {
    return []
  }

  const names = JSON.parse(item.stickers?.sticker_name)
  const ids = JSON.parse(item.stickers.sticker_id)
  const colors = JSON.parse(item.stickers.sticker_color)

  return names.map((name: string, i: number) => {
    return {
      id: ids[i],
      name: name,
      color: colors[i]
    }
  })
}

export function parseCartRecommendations(
  recommendations: CartRecommendations
): Array<CartRecommendation> {
  const parsedResult = recommendations.map((recommendation) => {
    const parsedItems: Array<RecommendationItem> = Object.values(recommendation.items).map(
      (item) => {
        return {
          discout: item.discount,
          hasModifier: item.has_modifier,
          id: item.id,
          image: item.img,
          name: item.name,
          price: +item.price,
          qty: item.qty
        }
      }
    )
    return {
      items: parsedItems,
      title: recommendation.name,
      type: recommendation.type
    }
  })
  return parsedResult
}
