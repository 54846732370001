<script setup lang="ts">
const {
  modalOrderTypeVisible,
  selectedAddress,
  selectedPickupPoint,
  selectedDineInPoint,
  isDelivery,
  isPickup,
  isDineIn
} = storeToRefs(useOrderStore())
</script>

<template>
  <div
    class="flex items-center gap-2.5 border-b p-2.5 dark:border-neutral-800"
    @click="modalOrderTypeVisible = true"
  >
    <template v-if="isDelivery && selectedAddress">
      <i-ri-truck-line class="shrink-0 text-primary" />
      <div>
        <div class="text-dark dark:text-neutral-200">
          Доставка по адресу: {{ selectedAddress.name }}
        </div>
        <div v-if="selectedAddress.addressLine" class="text-gray dark:text-neutral-400">
          {{ selectedAddress.addressLine }}
        </div>
      </div>
    </template>

    <template v-else-if="isPickup && selectedPickupPoint">
      <i-ri-map-pin-range-fill class="shrink-0 text-primary" />
      <div>
        <div class="text-dark dark:text-neutral-200">Самовывоз: {{ selectedPickupPoint.name }}</div>
        <div v-if="selectedPickupPoint.addressLine" class="text-gray dark:text-neutral-400">
          {{ selectedPickupPoint.addressLine }}
        </div>
      </div>
    </template>

    <template v-else-if="isDineIn && selectedDineInPoint">
      <i-ri-store-3-fill class="shrink-0 text-primary" />
      <div>
        <div class="text-dark dark:text-neutral-200">В заведении: {{ selectedDineInPoint.name }}</div>
        <div v-if="selectedDineInPoint.addressLine" class="text-gray dark:text-neutral-400">
          {{ selectedDineInPoint.addressLine }}
        </div>
      </div>
    </template>

    <template v-else>
      <i-ri-question-line class="shrink-0 text-primary" />
      <span>Как вы хотите получить заказ?</span>
    </template>

    <ModalOrderType />
  </div>
</template>
