<script setup lang="ts">
import type { Product } from '@/types/catalog'

const catalogStore = useCatalogStore()

const { search, showcase } = storeToRefs(catalogStore)

function onInput(event: Event) {
  const input = event.target as HTMLInputElement
  search.value = input.value
}

const allProducts = computed<Product[]>(() => {
  return (
    showcase.value?.categories.reduce((result, category) => {
      category.products.forEach((product) => {
        result.push(product)
      })
      return result
    }, [] as Product[]) || []
  )
})

const filteredProducts = computed<Product[]>(() => {
  if (!search.value) return []

  return allProducts.value.filter((product) => {
    const searchValue = search.value.toLowerCase().trim()
    const productName = product.name.toLowerCase()
    const stickers = product.stickers

    if (stickers?.some((sticker) => sticker.name.toLowerCase().includes(searchValue))) {
      return true
    }

    return productName.includes(searchValue)
  })
})
</script>

<template>
  <div class="space-y-4">
    <div class="relative flex items-center">
      <input
        :value="search"
        type="search"
        class="h-10 w-full rounded-lg border-neutral-200 bg-white pl-10 text-sm text-gray-dark transition placeholder:font-light placeholder:text-neutral-400 placeholder:transition focus:border-primary focus:outline-0 focus:ring-0 focus:ring-primary focus:placeholder:translate-x-1 dark:border-neutral-800 dark:bg-neutral-900 dark:text-neutral-200"
        placeholder="Поиск по меню"
        @input="onInput"
      />
      <i-ri-search-line class="absolute left-2 text-lg text-neutral-400" />
    </div>

    <section v-if="search">
      <h2 class="mb-2.5 text-xl font-bold text-dark dark:text-neutral-200">Результат поиска</h2>
      <ProductGrid v-if="filteredProducts.length">
        <ProductCard v-for="product in filteredProducts" :key="product.id" :product="product" />
      </ProductGrid>
      <div v-else>Ничего не найдено</div>
    </section>

    <div v-show="!search" class="space-y-4">
      <slot></slot>
    </div>
  </div>
</template>
