<script lang="ts" setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const props = defineProps<{ modelValue: boolean }>()

const hidePopup = () => {
  emit('update:modelValue', false)
}

const { modelValue: isVisible } = toRefs(props)

useModalOverlayObserver(isVisible)
</script>

<template>
  <TransitionRoot :show="props.modelValue" as="template">
    <Dialog class="relative z-10" @close="hidePopup">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-dark/50" data-overlay="true" aria-hidden="true"></div>
      </TransitionChild>

      <div class="fixed inset-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 translate-y-full"
          enter-to="opacity-100 translate-y-0"
          leave="duration-200 ease-in"
          leave-from="opacity-100 translate-y-0"
          leave-to="opacity-0 translate-y-full"
        >
          <DialogPanel
            class="absolute bottom-0 left-0 right-0 flex max-h-[75vh] flex-col rounded-t-lg bg-white shadow-base dark:bg-neutral-950"
          >
            <header class="container flex items-start gap-5 py-5">
              <DialogTitle class="text-lg font-bold leading-tight text-dark dark:text-neutral-200">
                <slot name="title" />
              </DialogTitle>
              <button class="ml-auto text-dark focus:outline-none dark:text-neutral-300" @click="hidePopup">
                <i-ri-close-fill />
              </button>
            </header>

            <div class="overflow-y-auto">
              <slot name="default" />
            </div>

            <footer class="footer">
              <slot name="footer" />
            </footer>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
