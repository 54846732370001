import type { User } from '@/types/user'

export const useUserStore = defineStore('user', () => {
  const api = useApi()

  const user = ref<User | null>(null)

  async function fetchUser() {
    user.value = await api.getUser()
  }

  return {
    user,
    fetchUser
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
