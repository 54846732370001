import chroma from 'chroma-js'
import { authToken, merchantKey } from '@/composables/api'

export function parseSearchParams() {
  const urlParams = new URLSearchParams(window.location.search)

  merchantKey.value =
    urlParams.get('merchantKey') || (import.meta.env.DEV && import.meta.env.VITE_MERCHANT_KEY) || ''

  if (!merchantKey.value) {
    throw new Error('Отсутствует параметр merchantKey')
  }

  authToken.value =
    urlParams.get('authToken') || (import.meta.env.DEV && import.meta.env.VITE_AUTH_TOKEN) || ''

  const color = urlParams.get('color')

  if (color && chroma.valid(color)) {
    useCssVar('--color-primary').value = chroma(color).rgb().join(' ')
  }

  const isDark = urlParams.get('dark')

  if (isDark) {
    document.querySelector('html')?.classList.add('dark')
  }

  const sessionMerchantKey = sessionStorage.getItem('merchantKey')
  if (sessionMerchantKey && sessionMerchantKey !== merchantKey.value) {
    sessionStorage.setItem('oldMerchantKey', sessionMerchantKey)
  } else {
    sessionStorage.removeItem('oldMerchantKey')
  }
  sessionStorage.setItem('merchantKey', merchantKey.value)
}
