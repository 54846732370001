import type { Utm } from '@/types/app'

export const useUtmStore = defineStore('utm', {
  persist: true,

  state: (): Utm => {
    return {
      source: null,
      campaign: null,
      medium: null
    }
  },

  actions: {
    clearUtm() {
      this.source = null
      this.campaign = null
      this.medium = null
    },

    setUtmFromQuery() {
      const source = useRouteQuery<string>('utm_source')
      const campaign = useRouteQuery<string>('utm_campaign')
      const medium = useRouteQuery<string>('utm_medium')

      if (source.value) {
        this.source = source.value
      }

      if (campaign.value) {
        this.campaign = campaign.value
      }

      if (medium.value) {
        this.medium = medium.value
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUtmStore, import.meta.hot))
}
