<script setup lang="ts">
import noPhotoImg from '@/assets/images/no-photo.png'

const image = ref<HTMLImageElement | null>(null)

whenever(image, (image) => {
  image.addEventListener('error', () => {
    image.src = noPhotoImg
  })

  if (image.src.includes('protected/modules/singlemerchant/assets/images/default-logo.png')) {
    image.src = noPhotoImg
  }
})
</script>

<template>
  <img ref="image" />
</template>
