<script setup lang="ts">
const giftStore = useGiftStore()
const { priceFormat } = useCurrency()

const hasLevel = computed<boolean>(() =>
  Boolean(giftStore.giftScale && giftStore.giftScale.currentLevel > 0)
)
</script>

<template>
  <div
    v-if="giftStore.giftScale"
    class="-mx-2 flex h-9 items-center gap-2 rounded-2xl bg-white dark:bg-neutral-800 px-2.5 shadow"
  >
    <div class="relative h-1.5 grow rounded-lg bg-neutral-200">
      <div
        class="absolute h-full rounded-lg bg-primary transition-all duration-300"
        :style="{
          width: `${giftStore.currentCheckpoint?.percent || giftStore.giftScale.percent || 0}%`
        }"
      ></div>
    </div>
    <div v-if="giftStore.currentCheckpoint?.value" class="text-lg font-bold text-primary">
      {{ priceFormat(giftStore.currentCheckpoint.value) }}
    </div>
    <div class="relative">
      <img
        src="@/assets/images/gift.svg"
        alt=""
        class="transition-all"
        :class="[hasLevel ? 'h-8' : 'h-6 grayscale']"
      />
      <span
        v-if="hasLevel"
        class="absolute -right-1 -top-1 grid h-5 w-5 place-items-center rounded-full bg-neutral-800 text-sm font-medium text-white"
      >
        {{ giftStore.giftScale.currentLevel }}
      </span>
    </div>
  </div>
</template>
