<script setup lang="ts">
import type { Address } from '@/types/order'

const router = useRouter()
const orderStore = useOrderStore()

const { selectedAddressId, addressList, selectedAddress } = storeToRefs(orderStore)

const { modalOrderTypeVisible, modalAddressVisible } = storeToRefs(useOrderStore())

function closeModals() {
  modalAddressVisible.value = false
  modalOrderTypeVisible.value = false
}

function onComplete() {
  closeModals()
}

function selectAddress(address: Address) {
  selectedAddressId.value = address.id
}

async function createAddress() {
  // closeModals()
  await router.push({
    name: 'DeliveryMap'
  })
}

async function editAddress(address: Address) {
  // closeModals()
  await router.push({
    name: 'DeliveryMap',
    params: {
      addressId: address.id
    }
  })
}
</script>

<template>
  <ModalBaseBottom v-model="modalAddressVisible">
    <template #title>Ваши адреса</template>

    <div class="container divide-y pb-5 dark:divide-neutral-800">
      <div v-if="addressList?.length === 0" class="py-3">У вас нет сохранённых адресов</div>

      <div v-for="address in addressList" :key="address.id" class="flex items-center gap-5 py-3">
        <button class="flex items-center gap-5 text-left" @click="selectAddress(address)">
          <div class="shrink-0">
            <i-ri-checkbox-fill v-if="selectedAddress === address" class="text-primary" />
            <i-ri-checkbox-blank-line v-else />
          </div>
          <div>
            <div class="text-dark dark:text-neutral-200">
              {{ address.addressLine || address.name }}
            </div>
          </div>
        </button>
        <button class="ml-auto shrink-0" @click="editAddress(address)">
          <i-ri-equalizer-fill />
        </button>
      </div>

      <div class="pb-2 pt-5">
        <button class="flex w-full items-center gap-5 text-dark dark:text-neutral-200" @click="createAddress">
          <i-ri-add-line />
          <span>Добавить новый адрес</span>
        </button>
      </div>
    </div>

    <template #footer>
      <div class="container pb-5">
        <button
          :disabled="!selectedAddress"
          class="block w-full rounded-lg bg-primary px-5 py-4 text-white transition disabled:opacity-25"
          @click="onComplete"
        >
          Готово
        </button>
      </div>
    </template>
  </ModalBaseBottom>
</template>
