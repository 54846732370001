<script setup lang="ts">
import { redirectTo } from '@/utils'
import type { BeginCheckoutEventData } from '@/composables/ecommerce'

const appStore = useAppStore()
const cartStore = useCartStore()
const orderStore = useOrderStore()
const giftStore = useGiftStore()
const userStore = useUserStore()
const { priceFormat } = useCurrency()
const router = useRouter()
const route = useRoute()
const toast = useToast()

const { errorMessage, selectedDeliveryArea, isFreeDelivery, isDelivery } = storeToRefs(orderStore)

const total = computed(() =>
  route.name === 'Checkout' ? cartStore.cart?.total : cartStore.cart?.subTotal
)

const isDisabled = computed<boolean>(() => Boolean(errorMessage.value) || loading.value)

const loading = ref(false)

const showDeliveryAreaDetails = ref(false)

async function onClick() {
  if (isDisabled.value) return

  if (route.name !== 'Checkout') {
    router.push({ name: 'Checkout' })
  } else {
    if (giftStore.needSelectGift) {
      giftStore.needSelectGiftModal = true
      giftStore.modalWasShown = true
      return
    }

    try {
      loading.value = true
      const result = await orderStore.createOrder()

      if (cartStore.cart) {
        const orderType = orderStore.selectedOrderType?.code

        const data: BeginCheckoutEventData = {
          id: result.id,
          income: {
            value: cartStore.cart.total,
            currency: 'RUB'
          },
          cost: {
            value: cartStore.cart.total,
            currency: 'RUB'
          },
          value: cartStore.cart.total,
          products: cartStore.cart.items?.map?.((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.qty
          })),
          point: result.pointName,
          custom: {
            merchant_id: appStore.settings.merchantId || ''
          },
          channel: 'loyalty',
          order_type: orderType || ''
        }

        if (orderType === 'delivery') {
          data.custom.delivery_zone = orderStore.selectedDeliveryArea?.name
          const coords = orderStore.selectedAddress?.coords
          if (Array.isArray(coords) && coords.length) {
            data.custom.geo = {
              lat: coords[0],
              lon: coords[1]
            }
          }
        }

        useEcommerce().bus.emit({
          name: 'orderSubmit',
          data
        })
      }

      useUtmStore().clearUtm()

      if (result.recurrent) {
        return await router.push({
          name: 'PaymentRecurrent',
          query: {
            orderId: result.id
          }
        })
      }

      if (result.redirect?.includes('qr.nspk.ru')) {
        return await router.push({
          name: 'Payment',
          query: {
            orderId: result.id,
            paymentURL: result.redirect
          }
        })
      }

      if (result.redirect) {
        router.push({
          name: 'Payment',
          query: {
            orderId: result.id,
            paymentURL: result.redirect
          }
        })
        return window.parent.postMessage({ type: 'open', url: result.redirect }, '*')
      }

      toast(result.message)

      if (cartStore.cart) {
        useEcommerce().bus.emit({
          name: 'purchase',
          data: {
            orderId: result.id,
            cart: cartStore.cart
          }
        })
      }

      redirectTo(`/payment-success/${result.id}`)

      await cartStore.fetchCart()
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      } else {
        alert(error)
      }
    } finally {
      loading.value = false
    }
  }
}

const addressCaption = computed(() =>
  [orderStore.selectedAddress?.street, orderStore.selectedAddress?.house].join(', ')
)
</script>

<template>
  <div class="space-y-2.5">
    <div class="px-2.5">
      <div v-if="errorMessage" class="flex items-center justify-center gap-2.5">
        <i-ri-error-warning-line class="text-primary" />
        <span class="text-dark dark:text-neutral-200">{{ errorMessage }}</span>
      </div>

      <template v-else-if="isDelivery && selectedDeliveryArea">
        <div v-if="showDeliveryAreaDetails" class="py-2 text-dark dark:text-neutral-200">
          <div class="mb-4 border-b pb-4 dark:border-neutral-800">
            <div class="flex items-start justify-between gap-4">
              <div class="space-y-1">
                <div class="font-bold">{{ addressCaption }}</div>
                <div v-if="selectedDeliveryArea.outerName" class="text-gray dark:text-neutral-400">
                  {{ selectedDeliveryArea.outerName }}
                </div>
              </div>
              <button @click="showDeliveryAreaDetails = false">
                <i-ri-arrow-right-s-line class="rotate-90 text-2xl" />
              </button>
            </div>
          </div>

          <DeliveryAreaDetails :delivery-area="selectedDeliveryArea" />
        </div>

        <div v-else class="flex items-center justify-between gap-2.5">
          <span v-if="isFreeDelivery">Бесплатная Доставка</span>

          <template v-else>
            <div v-if="selectedDeliveryArea.deliveryCost" class="text-dark dark:text-neutral-200">
              Доставка
              {{ priceFormat(selectedDeliveryArea.deliveryCost) }}
            </div>
            <div v-if="selectedDeliveryArea.freeDelivery" class="text-gray dark:text-neutral-400">
              Бесплатная от {{ priceFormat(selectedDeliveryArea.freeDelivery) }}
            </div>
          </template>

          <button @click="showDeliveryAreaDetails = true">
            <i-ri-arrow-right-s-line class="-rotate-90 text-dark dark:text-neutral-200" />
          </button>
        </div>
      </template>
    </div>

    <button
      :disabled="isDisabled"
      class="flex w-full items-center justify-between rounded-xl bg-primary px-2.5 py-4 text-white transition disabled:opacity-25"
      @click="onClick"
    >
      <span class="w-1/3 text-left">
        <!-- ~35-45 мин. -->
      </span>
      <span class="w-1/3 text-center">Заказать</span>
      <span class="w-1/3 text-right">
        <template v-if="total">{{ priceFormat(total) }}</template>
      </span>
    </button>
  </div>
</template>
