<script setup lang="ts">
const {
  orderTypes,
  selectedOrderType,
  modalOrderTypeVisible,
  modalPickupPointVisible,
  modalAddressVisible,
  modalDineInVisible
} = storeToRefs(useOrderStore())

const { loading } = storeToRefs(useAppStore())

const categoryId = useRouteQuery<string>('category')

if (!selectedOrderType.value && !categoryId.value) {
  modalOrderTypeVisible.value = true
}

async function next() {
  switch (selectedOrderType.value?.code) {
    case 'delivery':
      modalAddressVisible.value = true
      break

    case 'pickup':
      modalPickupPointVisible.value = true
      break

    case 'dinein':
      modalDineInVisible.value = true
      break

    default:
      break
  }
}

watch(modalOrderTypeVisible, async (isVisible) => {
  if (isVisible) return
  loading.value = true
  useCatalogObserver().clear()
  useCatalogStore().showcase = null
  await Promise.all([useCartStore().fetchCart(), useCatalogStore().fetchShowcase()]).finally(() => {
    loading.value = false
  })
})
</script>

<template>
  <ModalBaseBottom v-model="modalOrderTypeVisible">
    <template #title>Как вы хотите получить заказ?</template>

    <div class="container divide-y pb-5 dark:divide-neutral-800">
      <button
        v-for="orderType in orderTypes"
        :key="orderType.code"
        class="flex w-full items-center gap-3 py-4"
        :class="{ 'text-primary': selectedOrderType?.code === orderType.code }"
        @click="selectedOrderType = orderType"
      >
        <i-ri-checkbox-circle-fill v-if="selectedOrderType?.code === orderType.code" />
        <i-ri-checkbox-blank-circle-line v-else />
        <span class="text-lg">{{ orderType.label }}</span>
      </button>
    </div>

    <template #footer>
      <div class="container pb-5">
        <button
          class="block w-full rounded-lg bg-primary px-5 py-4 text-white transition disabled:opacity-25"
          :disabled="!selectedOrderType"
          @click="next"
        >
          Продолжить
        </button>
      </div>
    </template>

    <ModalPickupPoint />
    <ModalDineInPoint />
    <ModalAddress />
  </ModalBaseBottom>
</template>
