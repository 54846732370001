<template>
  <span
    class="rounded px-1.5 py-0.5 text-center font-light"
    :style="{
      background: sticker.color || 'black',
      color: textColor
    }"
  >
    {{ sticker.name }}
  </span>
</template>

<script setup lang="ts">
import chroma from 'chroma-js'
import type { Sticker } from '@/types/catalog'

const props = defineProps<{
  sticker: Sticker
}>()

const textColor = computed(() => {
  const color = props.sticker.color.toLowerCase()

  if (!chroma.valid(color)) return 'white'

  return chroma(color).luminance() >= 0.5 ? 'black' : 'white'
})
</script>
