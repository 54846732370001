import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './assets/index.css'
import App from './App.vue'
import { router } from './router'
import { registerPlugins } from './plugins'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://7b236552638f7accca898a84fafb9ff5@o4505505099218944.ingest.us.sentry.io/4508023526457344',
  integrations: []
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(router)
app.use(pinia)

registerPlugins(app)

router.isReady().then(() => app.mount('#app'))

useAppStore()
  .fetchInitialData()
  .catch((error) => {
    alert(error)
    throw error
  })
