import { Api } from '@/services/api'
import { ApiError } from '@/services/api/errors'
import { ofetch } from 'ofetch'
import qs from 'qs'

const urlParams = new URLSearchParams(window.location.search)

export const deviceId = urlParams.get('deviceId')
export const merchantKey = ref<string>()
export const authToken = ref<string>()

export function useApi() {
  const fetch = ofetch.create({
    baseURL: import.meta.env.DEV
      ? import.meta.env.VITE_BACKEND_URL || 'https://vsem-edu-oblako.ru'
      : 'https://vsem-edu-oblako.ru',
    responseType: 'json',
    onRequest: async ({ options }) => {
      const orderStore = useOrderStore()

      options.params = {
        lang: 'ru',
        json: true,
        merchant_keys: merchantKey.value,
        device_id: deviceId,
        device_platform: 'loyalty',
        token: authToken.value || '',
        point_delivery: orderStore.isPickup
          ? orderStore.selectedPickupPoint?.id || ''
          : orderStore.isDineIn
            ? orderStore.selectedDineInPoint?.id || ''
            : '',
        zone: orderStore.isDelivery ? orderStore.selectedDeliveryArea?.name : '',
        ...options.params
      }

      if (options.body) {
        options.body = qs.stringify(options.body)
        options.headers = {
          ...options.headers,
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }
    },
    onResponse: async ({ response }) => {
      const code = response._data?.code

      if (code !== 1) {
        throw new ApiError(response._data)
      }

      const warning = response._data?.details?.warning

      if (warning && useAppStore().appReady && window.location.pathname === '/checkout') {
        useToast().warning(warning)
      }
    }
  })

  return new Api(fetch)
}
