import Toast, { POSITION, useToast } from 'vue-toastification'
import type { App } from 'vue'
import type { PluginOptions } from 'vue-toastification'
import type { ToastOptionsAndRequiredContent } from 'vue-toastification/dist/types/types'

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  transition: 'Vue-Toastification__fade',
  filterBeforeCreate
}

export const useToastPlugin = (app: App) => {
  app.use(Toast, options)
}

export { useToast }

function filterBeforeCreate(
  toast: ToastOptionsAndRequiredContent,
  toasts: ToastOptionsAndRequiredContent[]
): false | ToastOptionsAndRequiredContent {
  if (toasts.find((t) => t.content == toast.content)) {
    return false
  }

  return toast
}
