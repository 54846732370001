import Showcase from './views/Showcase.vue'

export const routes = [
  {
    path: '/',
    component: Showcase,
    name: 'Index'
  },
  {
    path: '/catalog/:categoryId',
    component: () => import('./views/Category.vue'),
    name: 'Category'
  },
  {
    path: '/catalog/product/:productId',
    component: () => import('./views/ProductView.vue'),
    name: 'ProductDetails'
  },
  {
    path: '/delivery-map/:addressId?',
    component: () => import('./views/DeliveryMap.vue'),
    name: 'DeliveryMap'
  },
  {
    path: '/checkout',
    component: () => import('./views/Checkout.vue'),
    name: 'Checkout'
  },
  {
    path: '/payment',
    component: () => import('./views/Payment.vue'),
    name: 'Payment'
  },
  {
    path: '/payment-recurrent',
    component: () => import('./views/PaymentRecurrent.vue'),
    name: 'PaymentRecurrent'
  }
]
