<script setup lang="ts">
const { modalDineInVisible, dineInPoints, selectedDineInPoint, modalOrderTypeVisible } =
  storeToRefs(useOrderStore())

const next = () => {
  modalDineInVisible.value = false
  modalOrderTypeVisible.value = false
}
</script>

<template>
  <ModalBaseBottom v-model="modalDineInVisible">
    <template #title>Выберите заведение</template>

    <div class="container pb-5">
      <button
        v-for="point in dineInPoints"
        :key="point.id"
        class="flex w-full items-center gap-5 py-3 text-left"
        @click="selectedDineInPoint = point"
      >
        <i-ri-map-pin-line
          class="flex-shrink-0 text-2xl transition"
          :class="[
            selectedDineInPoint?.id === point.id ? 'text-primary' : 'text-gray dark:text-neutral-400'
          ]"
        />
        <div class="space-y-1">
          <p class="flex items-center gap-3">
            <span
              class="text-base leading-none transition"
              :class="[
                selectedDineInPoint?.id === point.id
                  ? 'text-primary'
                  : 'text-dark dark:text-neutral-200'
              ]"
            >
              {{ point.name }}
            </span>
          </p>
          <p v-if="point.addressLine" class="text-xs text-gray">
            {{ point.addressLine }}
          </p>
        </div>
      </button>
    </div>

    <template #footer>
      <div class="container pb-5">
        <button
          class="block w-full rounded-lg bg-primary px-5 py-4 text-white transition disabled:opacity-25"
          :disabled="!selectedDineInPoint"
          @click="next"
        >
          Готово
        </button>
      </div>
    </template>
  </ModalBaseBottom>
</template>
